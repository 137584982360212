<template>
  <div>
    <editor
      ref="editorRef"
      api-key="j7ta4b0c7h8ak56lc76r4o4bn8q3d3qr05qavj3vbr5jvsqy"
      :init="{
        height: 500,
        language: 'zh_CN',
        branding: false,
        menubar: false,
        plugins: 'preview fullscreen code table hr pagebreak lists',
        toolbar: [
          'code undo redo | styleselect | fontsizeselect | bold forecolor backcolor lineheight | blockquote removeformat',
          'alignleft aligncenter alignright alignjustify outdent indent bullist numlist  | table hr pagebreak preview fullscreen | media',
        ],
        setup: function (editor) {
          thatBoj.editorObj = editor;
          editor.ui.registry.addButton('media', {
            text: '图片上传',
            onAction(res) {
              thatBoj.$refs.imgUploadButton.click();
            },
          });
        },
      }"
      v-model="contentValue"
    />
    <!-- 自定义工具组件 -->
    <div class="toolBox">
      <el-upload
        class="avatar-uploader"
        action
        :data="{ type: 'thumb' }"
        :http-request="uploadImage"
        :show-file-list="false"
        :multiple="false"
      >
        <i class="el-icon-plus" ref="imgUploadButton"></i>
      </el-upload>
    </div>
  </div>
</template>

<script>
import user from '../components/global.vue'
var timeout = null;
import editor from "@tinymce/tinymce-vue";
import axios from 'axios'
export default {
  components: {
    editor,
  },
  props: {
    content: {
      type: String,
      default: "",
    },
    height: {
      type: Number,
      default: 280,
    },
  },
  data () {
    return {
      editorObj: {},
      mediaStatus: false,
      contentValue: "",
      userSite: user.userSite,
    };
  },
  computed: {
    thatBoj: function () {
      return this;
    },
  },
  watch: {
    contentValue (newValue) {
      this.debounce(() => {
        this.$emit("update:content", newValue);
      }, 500);
    },
    content (newValue) {
      this.contentValue = newValue;
    },
  },
  mounted () {
    this.contentValue = this.content;
  },
  methods: {
    uploadImage (param) {
      let file = param.file;
      const form = new FormData();
      form.append("file", file);
      this.upload("/api/website/upload_file", form).then((res) => {
        this.editorObj.insertContent(`<img src='${this.userSite + res.url}'>`);
      });
    },

    upload (url, params) {
      return axios
        .post(url, params, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          return new Promise((resolve, reject) => {
            resolve(res);
          });
        })
        .catch((err) => {
          console.log("err:", err);
        });
    },

    //防抖
    debounce (func, wait = 500, immediate = false) {
      // 清除定时器
      if (timeout !== null) clearTimeout(timeout);
      // 立即执行，此类情况一般用不到
      if (immediate) {
        var callNow = !timeout;
        timeout = setTimeout(function () {
          timeout = null;
        }, wait);
        if (callNow) typeof func === "function" && func();
      } else {
        // 设置定时器，当最后一次操作后，timeout不会再被清除，所以在延时wait毫秒后执行func回调方法
        timeout = setTimeout(function () {
          typeof func === "function" && func();
        }, wait);
      }
    },
  },
};
</script>

<style  scoped>
div/deep/.el-upload {
  display: none;
}

.tox-tinymce-aux {
  z-index: 10000 !important;
}
div/deep/.tox {
  margin-top: 20px;
}
</style>
