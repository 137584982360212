<template>
  <div>
    <Header title="政策资讯" back="返回" index="首页" titleOne="政策管理" titleTwo="政策资讯" beforeTitle="新增编辑" />

    <div
      class="content"
      v-loading="loading"
      element-loading-text="附件上传中···"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.5)"
    >
      <h1>政策资讯</h1>
      <el-form ref="form" :model="policy" label-position="top">
        <el-form-item label="政策名称">
          <el-input v-model="policy.title" class="policy_name"></el-input>
        </el-form-item>
        <el-form-item class="policy" label="政策类型">
          <el-select v-model="policy.classify" placeholder="请选择">
            <el-option
              v-for="item in selectlist"
              :label="item.text"
              :value="item.value"
              :key="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="policy" label="开始时间">
          <el-date-picker
            v-model="policy.effective_time1"
            type="date"
            placeholder="选择日期"
            value-format="yyyy-MM-dd"
          ></el-date-picker>
        </el-form-item>
        <el-form-item class="policy" label="结束时间">
          <el-date-picker
            v-model="policy.effective_time2"
            type="date"
            placeholder="选择日期"
            value-format="yyyy-MM-dd"
          ></el-date-picker>
        </el-form-item>
        <el-form-item class="policy" label="受理部门">
          <el-input v-model="policy.organization"></el-input>
        </el-form-item>
        <el-form-item class="policy" label="所属区域">
          <el-select v-model="country" placeholder="请选择国家">
            <el-option label="中国" value="china"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="policy" prop="province_id">
          <el-select v-model="policy.province_id" placeholder="请选择省" @change="getcity">
            <el-option v-for="item in province" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="policy" prop="city_id">
          <el-select v-model="policy.city_id" placeholder="请选择市" @change="gettown">
            <el-option v-for="item in city" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="policy" prop="town_id">
          <el-select v-model="policy.town_id" placeholder="请选择区">
            <el-option v-for="item in town" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="localtion" class="policy_name" label="政策来源">
          <el-input v-model="policy.location"></el-input>
        </el-form-item>
      </el-form>
      <!-- 富文本编辑框 -->
      <div class="about">
        <span class="policy_text">政策内容</span>
        <editor :content.sync="policy.content"></editor>
      </div>
      <div class="upload">
        <el-upload
          class="upload-demo"
          ref="imgupload"
          action="string"
          :http-request="httpRequest"
          :show-file-list="false"
          :before-upload="beforeAvatarUpload"
          :file-list="fileList"
        >
          <el-button type="primary" class="ChooseFile">请上传相关附件</el-button>
        </el-upload>
        <span>（上传文件格式支持pdf，jpg，word，压缩包zip/rar）</span>
      </div>
      <el-table :data="get_file_list" style="width: 100%">
        <el-table-column prop="name" label="附件名称" width="700"></el-table-column>
        <el-table-column prop label="上传时间" width="300"></el-table-column>
        <el-table-column prop="address" label="操作">
          <template slot-scope="scope">
            <el-button class="edit" size="mini" @click="file_up(scope.row)">
              <a :href="downlink + up_url" :download="up_name" target="_blank">下载</a>
            </el-button>
            <el-button class="edit" size="mini" @click="file_look(scope.row)">
              <a :href="downlink + up_url" :download="up_name" target="_blank">查看</a>
            </el-button>
            <el-button class="edit clear" size="mini" @click="file_clear(scope.row.id)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="policy_sure">
        <el-button type="primary" :loading="btnLoading" class="policy_go" @click="policy_sure()">确定</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import download from "../../components/global.vue";
import axios from "axios";
import Editor from '../../components/tinymce.vue'
export default {
  components: {
    Editor,
  },
  data () {
    return {
      btnLoading: false,//提交按钮动画
      downlink: download.userSite,
      loading: false,
      token: "",
      policy: {
        effective_time1: "",
        effective_time2: "",
      },
      country: "中国",
      content: "",
      province: [],
      city: [],
      town: [],
      fileList: [],
      get_file_list: [],
      up_url: "", //附件下载链接
      up_name: "", //附件下载名称
      selectlist: [
        //classify下拉菜单列表
        {
          value: 1,
          text: "科技政策",
        },
        {
          value: 2,
          text: "科技项目",
        },
        {
          value: 3,
          text: "立项公告",
        },
        {
          value: 4,
          text: "申报通知",
        },
      ],
    };
  },
  watch: {
    "policy.province_id": {
      handler (val, oval) {
        this.getcity(val);
      },
      deep: true,
    },
    "policy.city_id": {
      handler (val, oval) {
        this.gettown(val);
      },
      deep: true,
    },
    "policy.classify": {
      handler (val, oval) {
        console.log(val, "val");
      },
      deep: true,
    },
  },
  created () {
    this.getprovince();
    if (this.$route.query.id) {
      this.get_file();
      this.axios
        .get("/api/policy/policy_detail", {
          params: { id: this.$route.query.id },
        })
        .then((res) => {
          this.policy = res.data;
        });
    } else {
      console.log("新增跳转");
    }
    this.token = localStorage.getItem("token");
  },
  methods: {
    // 获取附件列表
    get_file () {
      this.axios
        .get("/api/policy/get_policy_file", {
          params: { id: this.$route.query.id },
        })
        .then((res) => {
          console.log(res, "附件列表");
          this.get_file_list = res.data;
        });
    },
    // 附件列表操作-下载
    file_up (row) {
      console.log(row, "下载");
      this.up_url = row.url;
      this.up_name = row.name;
    },
    // 附件列表操作-查看
    file_look (row) {
      this.up_url = row.url;
      this.up_name = row.name;
    },
    // 附件列表操作-删除
    file_clear (id) {
      console.log(id, "附件id");
      this.$confirm("确定删除此项目数据，删除后无法恢复！", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.axios
          .delete("/api/policy/delete_policy_file", {
            params: {
              token: this.token,
              id: id,
            },
          })
          .then(() => {
            this.$message({
              type: "success",
              message: "删除成功",
            });
            this.get_file();
          });
      });
    },
    policy_sure () {
      this.btnLoading = true
      this.axios.post("/api/policy/edit_policy", this.policy).then((res) => {
        this.$message({
          type: "success",
          message: "保存成功！",
        });
        this.btnLoading = false
        this.$router.go(-1);
      }).catch(() => {
        this.btnLoading = false
      })
    },
    httpRequest (param) {
      this.loading = true;
      let file = param.file;
      const form = new FormData();
      form.append("file", file);
      form.append("token", this.token);
      form.append("id", this.$route.query.id);
      this.upload("/api/policy/upload_policy_file", form).then((res) => {
        this.code = res.code;
        console.log(res);
        this.dialogFormVisible = false;
        if (res.code == 1) {
          this.$message({
            type: "success",
            message: "上传附件成功",
          });
          this.loading = false;
          this.get_file();
        } else {
          this.$message({
            type: "error",
            message: "上传附件失败",
          });
        }
      });
    },
    upload (url, params) {
      return axios
        .post(url, params, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          return new Promise((resolve, reject) => {
            resolve(res);
          });
        })
        .catch((err) => {
          console.log("err:", err);
        });
    },
    // 上传文件前的过滤
    beforeAvatarUpload (file) {
      const isLt20M = file.size / 1024 / 1024 < 20;
      if (!isLt20M) {
        this.$message.error("上传文件大小不能超过 20MB!");
      }
      return isLt20M;
    },
    getprovince () {
      this.axios.get("/api/region/getProvince").then((res) => {
        this.province = res.data;
      });
    },
    getcity (id) {
      this.axios
        .get("/api/region/getCity", {
          params: {
            province_id: id,
          },
        })
        .then((res) => {
          this.city = res.data;
        });
    },
    gettown (id) {
      this.axios
        .get("/api/region/getTown", {
          params: {
            city_id: id,
          },
        })
        .then((res) => {
          this.town = res.data;
        });
    },
  },
};
</script>

<style scoped>
::v-deep.el-input {
  width: 100%;
  display: inline-block !important;
}
::v-deep.el-select {
  width: 100%;
  display: inline-block !important;
}
div/deep/.policy {
  width: 23%;
  display: inline-block;
  margin-right: 2%;
}
.policy_name {
  width: 98% !important;
}
div/deep/.el-form-item__label {
  color: #404040;
}
div/deep/.ql-toolbar.ql-snow + .ql-container.ql-snow {
  height: 250px;
}
div/deep/.ql-toolbar {
  margin-top: 10px;
}
.policy_text {
  font-size: 16px;
  font-family: Microsoft YaHei, Microsoft YaHei-Regular;
  font-weight: 400;
  color: #404040;
}
.policy_sure {
  width: 98%;
  text-align: center;
}
.policy_go {
  margin: 50px 0 20px 0;
}
div/deep/.el-icon-loading {
  color: #fff !important;
  font-size: 24px !important;
}
div/deep/ .el-loading-text {
  color: #fff;
  font-size: 16px;
}
.upload {
  display: flex;
  align-items: center;
  margin: 30px 0;
}
.upload span {
  margin-left: 15px;
  color: #a6abc7;
}
.upload .ChooseFile {
  width: 370px;
  height: 40px;
  background: #edeef4;
  border: none;
  color: #909399;
}
div/deep/.clear span {
  color: #ff0f0f;
}
.clear {
  background: #f9f2f2 !important;
}
</style>
