var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('editor',{ref:"editorRef",attrs:{"api-key":"j7ta4b0c7h8ak56lc76r4o4bn8q3d3qr05qavj3vbr5jvsqy","init":{
      height: 500,
      language: 'zh_CN',
      branding: false,
      menubar: false,
      plugins: 'preview fullscreen code table hr pagebreak lists',
      toolbar: [
        'code undo redo | styleselect | fontsizeselect | bold forecolor backcolor lineheight | blockquote removeformat',
        'alignleft aligncenter alignright alignjustify outdent indent bullist numlist  | table hr pagebreak preview fullscreen | media' ],
      setup: function (editor) {
        _vm.thatBoj.editorObj = editor;
        editor.ui.registry.addButton('media', {
          text: '图片上传',
          onAction: function onAction(res) {
            _vm.thatBoj.$refs.imgUploadButton.click();
          },
        });
      },
    }},model:{value:(_vm.contentValue),callback:function ($$v) {_vm.contentValue=$$v},expression:"contentValue"}}),_c('div',{staticClass:"toolBox"},[_c('el-upload',{staticClass:"avatar-uploader",attrs:{"action":"","data":{ type: 'thumb' },"http-request":_vm.uploadImage,"show-file-list":false,"multiple":false}},[_c('i',{ref:"imgUploadButton",staticClass:"el-icon-plus"})])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }